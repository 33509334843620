import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { scroller } from 'react-scroll'
import Svg from '../../Svg'
import { arrowDown } from '../../Svg/elements'

export const ScrollButton = styled.div`
  color: ${({ theme, color }) => color || theme.colors.white};
  margin: 30px auto 44px;
  text-align: center;
  cursor: pointer;
  @media (min-width: 992px) {
    margin: 60px auto 84px;
  }

  &:hover {
    color: ${({ hoverColor }) => hoverColor || null};
    outline: none;
    text-decoration: none;
  }
`

const ScrollLink = ({
  scrollTo,
  duration = '800',
  delay = '0',
  smooth = 'easeInOutQuart'
}) => (
  <ScrollButton
    onClick={() =>
      scroller.scrollTo(scrollTo, {
        duration: duration,
        delay: delay,
        smooth: smooth
      })
    }
    aria-label=''
  >
    <Svg d={arrowDown} />
  </ScrollButton>
)

ScrollLink.propTypes = {
  scrollTo: string.isRequired,
  color: string,
  hoverColor: string
}

export default ScrollLink
