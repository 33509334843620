import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { CustomLink } from '../custom-link'
import { IconButtonCtaArrow } from '../icons'
import Img from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    marginBottom: '24px',
    background: '#fff',
    boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.13)',
    height: '155px'
  },
  cardLg: {
    height: '335px'
  },
  number: {
    backgroundColor: '#b4975a',
    fontSize: '95px',
    color: theme.palette.common.white,
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '1',
    '&:before': {
      content: '"stage"',
      fontSize: '20px'
    }
  },
  image: {
    width: '185px',
    backgroundColor: '#f5f5f5'
  },
  content: {
    flex: '1'
  },
  ctaButton: {
    paddingLeft: '0',
    paddingRight: '0',
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  }
}))

const StagesCard = ({ item, images }) => {
  const classes = useStyles()
  const isTablet = useMediaQuery(useTheme().breakpoints.down('md'))
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <Box className={`${classes.card} ${item.id == 7 ? classes.cardLg : ''}`}>
      <Box className={classes.number} style={{ backgroundColor: item.style }}>
        {item.id}
      </Box>
      {!isMobile && (
        <Box className={classes.image}>
          <Img fixed={images[`${item.image}`]?.childImageSharp.fixed} />
        </Box>
      )}
      <Box className={classes.content}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pt={2}
            px={2}
            style={{ borderBottom: '1px solid #dfdfdf' }}
          >
            <Typography variant="h5" color="secondary">
              {item.title}
            </Typography>
            <Box p={1} style={{ borderLeft: '1px solid #dfdfdf' }}>
              <Typography variant="caption" color="secondary" component={'div'}>
                {item.stagesLine1}
              </Typography>
              <Typography variant="caption" color="secondary" component={'div'}>
                {item.stagesLine2}
              </Typography>
            </Box>
          </Box>
          <Box p={2}>
            <Button
              variant="text"
              color="secondary"
              as={CustomLink}
              href={item.url}
              target="_blank"
              endIcon={<IconButtonCtaArrow />}
              className={classes.ctaButton}
            >
              Learn More
            </Button>
          </Box>
          {item.extraImage && !isTablet && (
            <Box className={classes.extraImage}>
              <Img
                fluid={images[`${item.extraImage}`]?.childImageSharp.fluid}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default StagesCard
