import React from 'react'
import StagesCard from './stages-card'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const stages = [
  {
    id: '1',
    title: 'Active Start',
    date: '2015-05-01T22:12:03.284Z',
    image: 'StageImg1',
    stagesLine1: 'GIRLS: 0-6',
    stagesLine2: 'BOYS: 0-6',
    style: '#b4975a',
    url: '/Active_Start.pdf',
    description:
      'Gets kids moving and eventually linking those smaller movements together in play.'
  },
  {
    id: '2',
    title: 'FUNdamentals',
    image: 'StageImg2',
    stagesLine1: 'GIRLS: 6-8',
    stagesLine2: 'BOYS: 6-9',
    style: '#4F758B',
    url: '/FUNdamentals.pdf',
    description:
      'Develops movement skills for play focused on fun, inclusive, multisport, and developmentally appropriate activities.'
  },
  {
    id: '3',
    title: 'Learn to Play',
    image: 'StageImg3',
    stagesLine1: 'GIRLS: 8-11',
    stagesLine2: 'BOYS: 9-12',
    style: '#A2AAAD',
    url: '/Learn_to_Play_rev.pdf',
    description:
      'Further develops all fundamental movement skills and teaching of general golf skills.'
  },
  {
    id: '4',
    title: 'Play to Improve',
    image: 'StageImg4',
    stagesLine1: 'GIRLS: 11-15',
    stagesLine2: 'BOYS: 12-16',
    style: '#B7BF10',
    url: '/Play_to_Improve.pdf',
    description:
      'Introduces training to further develop golf specific skills based around puberty and growth spurts.'
  },
  {
    id: '5',
    title: 'Play to Compete',
    image: 'StageImg5',
    stagesLine1: 'GIRLS: 15-21',
    stagesLine2: 'BOYS: 16-23',
    style: '#4F758B',
    url: '/Play_to_Compete.pdf',
    description:
      'Teaches golfers to perform under a variety of conditions during training encouraging the athlete’s competitive spirit'
  },
  {
    id: '6',
    title: 'Play to Win',
    image: 'StageImg6',
    stagesLine1: 'GIRLS: 18+',
    stagesLine2: 'BOYS: 19+',
    style: '#719949',
    url: '/Play_to_Win_Girl.pdf',
    description:
      'Reinforces, refines and optimizes technical, tactical and ancillary skills with an end goal of winning.'
  },
  {
    id: '7',
    title: 'Play Golf for Life',
    image: 'StageImg7',
    stagesLine1: 'ALL AGES',
    style: '#b4975a',
    extraImage: 'featuredImg',
    url: '/Play_Golf_for_Life_1.pdf',
    description:
      'Encourages fun and inclusive programing for golfers of all ages and ability levels.'
  }
]

export const useStyles = makeStyles(theme => ({
  root: () => ({
    padding: '40px 0 40px',
    '& h4': {
      marginBottom: '32px'
    }
  })
}))

const StagesSet = ({ images }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Typography variant="h4" color="secondary" align="center">
          How to Explain Each ADM Age and Stage to Parents
        </Typography>
        <Grid
          container
          spacing={3}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item xs={12} lg={6} md={7}>
            {stages
              .slice(0, Math.floor(stages.length / 2) + 1)
              .map((item, i) => (
                <StagesCard item={item} i={i} key={i} images={images} />
              ))}
          </Grid>
          <Grid item xs={12} lg={6} md={7}>
            {stages.slice(4, 7).map((item, i) => (
              <StagesCard item={item} i={i} key={i} images={images} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default StagesSet
