import React from 'react'

export const IconArrow = props => (
  <svg
    width="21"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4531 6.89929H0.71875C0.511111 6.89929 0.33941 6.81361 0.203646 6.64225C0.067882 6.47089 0 6.25416 0 5.99208C0 5.73 0.067882 5.51327 0.203646 5.34191C0.33941 5.17055 0.511111 5.08487 0.71875 5.08487H15.4771L13.4167 2.48419C13.2889 2.32291 13.225 2.1213 13.225 1.87938C13.225 1.63746 13.2969 1.42577 13.4406 1.24433C13.5844 1.06289 13.7521 0.972168 13.9438 0.972168C14.1354 0.972168 14.3031 1.06289 14.4469 1.24433L17.7052 5.35703C17.7851 5.45783 17.841 5.55863 17.8729 5.65944C17.9049 5.76024 17.9208 5.87112 17.9208 5.99208C17.9208 6.11304 17.9049 6.22392 17.8729 6.32473C17.841 6.42553 17.7851 6.52633 17.7052 6.62713L14.4469 10.7398C14.2872 10.9414 14.1155 11.0372 13.9318 11.0271C13.7481 11.017 13.5844 10.9213 13.4406 10.7398C13.2969 10.5584 13.225 10.3417 13.225 10.0897C13.225 9.83766 13.2889 9.63101 13.4167 9.46973L15.4531 6.89929Z"
      fill="currentColor"
    />
  </svg>
)

export const IconButtonCtaArrow = props => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4531 6.89929H0.71875C0.511111 6.89929 0.33941 6.81361 0.203646 6.64225C0.067882 6.47089 0 6.25416 0 5.99208C0 5.73 0.067882 5.51327 0.203646 5.34191C0.33941 5.17055 0.511111 5.08487 0.71875 5.08487H15.4771L13.4167 2.48419C13.2889 2.32291 13.225 2.1213 13.225 1.87938C13.225 1.63746 13.2969 1.42577 13.4406 1.24433C13.5844 1.06289 13.7521 0.972168 13.9438 0.972168C14.1354 0.972168 14.3031 1.06289 14.4469 1.24433L17.7052 5.35703C17.7851 5.45783 17.841 5.55863 17.8729 5.65944C17.9049 5.76024 17.9208 5.87112 17.9208 5.99208C17.9208 6.11304 17.9049 6.22392 17.8729 6.32473C17.841 6.42553 17.7851 6.52633 17.7052 6.62713L14.4469 10.7398C14.2872 10.9414 14.1155 11.0372 13.9318 11.0271C13.7481 11.017 13.5844 10.9213 13.4406 10.7398C13.2969 10.5584 13.225 10.3417 13.225 10.0897C13.225 9.83766 13.2889 9.63101 13.4167 9.46973L15.4531 6.89929Z"
      fill="currentColor"
    />
  </svg>
)
