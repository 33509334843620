import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AppStoreBadge from '../images/store-badges/app-store-badge.svg'
import GooglePlayBadge from '../images/store-badges/google-play-badge.png'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    backgroundColor: theme.palette.grey.darkest,
    zIndex: 2,
    position: 'relative'
  }),
  utilityContainer: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.dark,
    padding: '1.5rem 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  copyrightContainer: {
    order: 1,
    [theme.breakpoints.down('md')]: {
      order: 2,
      flexGrow: 1,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      order: 3,
      flexGrow: 1,
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      flex: 0.2
    }
  },
  copyright: {
    fontSize: '.75rem',
    lineHeight: '0.8rem',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  utilityLinksContainer: {
    order: 2,
    [theme.breakpoints.up('lg')]: {
      flex: 1
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      order: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '.9375rem'
    }
  },
  link: {
    position: 'relative',
    display: 'block',
    color: theme.palette.common.white,
    lineHeight: '1.125rem',
    fontSize: '.75rem',
    '&:hover': {
      color: theme.palette.common.white
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      paddingLeft: theme.spacing(4),
      '&:not(:first-of-type)': {
        '&:before': {
          content: '""',
          position: 'absolute',
          height: '100%',
          width: '1px',
          background: theme.palette.common.white,
          left: theme.spacing(2)
        }
      }
    }
  },
  badge: {
    width: 'auto',
    height: '36px',
    margin: '0 6px'
  }
}))

const Footer = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'center' : 'flex-start'}
          justifyContent="space-between"
          pt={isMobile ? 2 : 3}
          pb={isMobile ? 2 : 6}
        >
          <Box>
            <img
              src="https://assets-global.website-files.com/6352c70fd33e6a79421e54ef/650ddc77cf7abc623ab34f68_pgaa-logo-rev.png"
              width="47"
              height="42"
              alt=""
            />
          </Box>
          <Box
            display="none"
            alignItems="center"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box maxWidth="276px">
              <div className={`${classes.copyright}`}>
                Download the PGA Coach app to stay connected with your students
                and manage your business.
              </div>
            </Box>
            <Box className={classes.badgeContainer}>
              <a
                href="https://apps.apple.com/us/app/pga-coach/id1448721089"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStoreBadge}
                  alt="MyPGA app on App Store"
                  className={classes.badge}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.pga.pgacoach"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePlayBadge}
                  alt="MyPGA app on Google Play"
                  className={classes.badge}
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box className={classes.utilityContainer}>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} lg className={classes.copyrightContainer}>
              <span className={`${classes.copyright}`}>
                © Copyright PGA of America {new Date().getFullYear()}.
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg
              className={classes.utilityLinksContainer}
            >
              <a
                className={classes.link}
                href="https://www.pga.org/privacy-policy/"
              >
                Privacy Policy
              </a>
              <a
                className={classes.link}
                href="/pga-of-america/california-privacy-notice/"
              >
                California Privacy Policy
              </a>

              <a
                className={classes.link}
                href="/pga-of-america/terms-of-service/"
              >
                Terms of Service
              </a>
              <a className={classes.link} href="/pga-of-america/do-not-sell/">
                Do Not Sell My Information
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
