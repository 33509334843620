import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { string, object } from 'prop-types'
import SEO from '../SEO'
import Footer from './../../footer'
import Header from './../../header'
import themeRedesignMUI from '../../../mui-theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { createGlobalStyle } from 'styled-components'

const Global = createGlobalStyle`
  html {
    scroll-padding-top: 64px;
    @media (max-width: 978px) {
      scroll-padding-top: 45px;
    }
  }
`
const fadeIn = keyframes`
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`
const Fade = styled.div`
  -webkit-animation: ${fadeIn} 2s linear;
  -moz-animation: ${fadeIn} 2s linear;
  -ms-animation: ${fadeIn} 2s linear;
  -o-animation: ${fadeIn} 2s linear;
  animation: ${fadeIn} 2s linear;
  div {
    -webkit-animation: ${fadeIn} 2s linear;
    -moz-animation: ${fadeIn} 2s linear;
    -ms-animation: ${fadeIn} 2s linear;
    -o-animation: ${fadeIn} 2s linear;
    animation: ${fadeIn} 2s linear;
  }
  * {
    -webkit-font-smoothing: antialiased;
  }
`

const LayoutV2 = ({ children }) => (
  <>
    <SEO />
    <Global />
    <Fade>
      <ThemeProvider theme={themeRedesignMUI}>
        <Header />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </Fade>
  </>
)

LayoutV2.propTypes = {
  title: string,
  query: object
}
export default LayoutV2
