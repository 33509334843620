import TextLink from '../TextLink'
import styled from 'styled-components'
import React from 'react'
import { string, bool } from 'prop-types'

export const ButtonLinkWrapper = styled.span`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.red};
  transition: background-color 0.2s linear;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid
    ${({ borderColor, theme }) => borderColor || theme.colors.transparent};
  a {
    text-transform: uppercase;
  }
  &:hover {
    background-color: ${({ theme, bgHoverColor }) =>
    bgHoverColor || theme.colors.gray};
    border: 1px solid
      ${({ borderHoverColor, theme }) =>
    borderHoverColor || theme.colors.transparent};
    a {
      color: ${({ theme, hoverColor }) => hoverColor || theme.colors.white};
    }
  }
  @media (max-width: 992px) {
    margin-top: ${({ marginTop }) => marginTop};
  }
  &.secondary {
    border-radius: 4px;
    letter-spacing: 0;
    text-transform: uppercase;

    line-height: 28px;
    a {
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
    }
  }
`

const ButtonLinkV2 = ({
  url,
  marginTop,
  bgHoverColor,
  bgColor,
  borderColor,
  borderHoverColor,
  hoverColor,
  children,
  btnStyle,
  ...textStyles
}) => (
  <ButtonLinkWrapper
    marginTop={marginTop}
    bgColor={bgColor}
    bgHoverColor={bgHoverColor}
    borderColor={borderColor}
    borderHoverColor={borderHoverColor}
    hoverColor={hoverColor}
    className={btnStyle}
  >
    <TextLink url={url} padding='10px 20px' {...textStyles}>
      {children}
    </TextLink>
  </ButtonLinkWrapper>
)

ButtonLinkV2.propTypes = {
  url: string.isRequired,
  color: string,
  hoverColor: string,
  underline: bool,
  bgColor: string,
  bgHoverColor: string,
  borderColor: string,
  padding: string,
  btnStyle: string
}

export default ButtonLinkV2
